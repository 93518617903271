import React, { useEffect, useState } from "react";
import { useAuth } from "../../AuthProvider";
import { getUserProfile, updateUserProfile } from "../../firebase/userService";
import { auth, db } from "../../firebase/firebaseConfig";
import "../Profile/profile.scss";
import { ref, get, set } from "firebase/database";

const Profile = () => {
  const { currentUser } = useAuth();
  const [profile, setProfile] = useState({
    email: "",
    name: "",
    address: "",
    phone: "",
  });

  useEffect(() => {
    const fetchUserProfile = async () => {
      if (currentUser) {
        const userRef = ref(db, "users/" + currentUser.uid);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          setProfile(snapshot.val());
        } else {
          setProfile((prevProfile) => ({
            ...prevProfile,
            email: currentUser.email,
          }));
        }
      }
    };

    fetchUserProfile();
  }, [currentUser]);

  if (!currentUser) {
    return <div>Загрузка...</div>; 
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (currentUser) {
      try {
        await updateUserProfile(currentUser.uid, profile);
        alert("Изменения сохранены");
      } catch (error) {
        console.error("Error updating profile:", error);
        alert("Error updating profile");
      }
    }
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      window.location.href = "/";
    } catch (error) {
      console.error("Ошибка при выходе:", error);
    }
  };

  return (
    <div className="profile">
      <h2 className="profile__title">Профиль</h2>
      <form onSubmit={handleSubmit} className="profile__form">
        <input
          className="profile__input profile__input--email"
          type="email"
          name="email"
          value={currentUser.email}
          readOnly
          placeholder="Email"
        />
        <input
        className="profile__input profile__input--name"
          type="text"
          name="name"
          value={profile.name}
          onChange={handleChange}
          placeholder="Имя"
        />
        <input
        className="profile__input profile__input--address"
          type="text"
          name="address"
          value={profile.address}
          onChange={handleChange}
          placeholder="Адрес"
        />
        <input
        className="profile__input profile__input--phone"
          type="text"
          name="phone"
          value={profile.phone}
          onChange={handleChange}
          placeholder="Номер телефона"
        />
        <button type="submit" className="profile__button profile__button--submit">Сохранить</button>
      </form>
      <button onClick={handleLogout} className="profile__button profile__button--logout">
        Выйти
      </button>
    </div>
  );
};

export default Profile;
