import React, { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchProducts } from "../../redux/slices/productSlice";
import ProductBlock from "../../components/ProductBlock/ProductBlock";
import Information from "../../components/Information/Information";
import "../../components/Categories/categories.scss";
import "../Home/home.scss";
import logoSvg from "../../assets/img/pizza-logo.svg";

const Home = () => {
  const dispatch = useDispatch();
  const [category, setCategory] = React.useState(0); 
  useEffect(() => {
    dispatch(fetchProducts(category));
  }, [dispatch, category]);

  const scrollToContent = (id, offsetFromTop) => {
    if (id === "top") {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    } else {
      const element = document.getElementById(id);
      if (element) {
        const elementPosition = element.offsetTop;
        const offsetPosition = elementPosition - offsetFromTop;

        window.scrollTo({
          top: offsetPosition,
          behavior: "smooth",
        });
      }
    }
  };

  const handleScroll = () => {
    const contentTop = document.querySelector(".content__top");
    if (contentTop) {
      if (window.scrollY > 100) {
        contentTop.classList.add("show-logo");
      } else {
        contentTop.classList.remove("show-logo");
      }
    }
  };

  React.useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  /*
  const products = useSelector((state) => state.products.products);
  const [items, setItems] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const [categoryId, setCategoryId] = React.useState(0);
  
  
  const { totalPrice } = "100";
  const status = useSelector((state) => state.products.status);
  const error = useSelector((state) => state.products.error);
*/
  const products = useSelector((state) => state.products.products);
  const status = useSelector((state) => state.products.status);
  const error = useSelector((state) => state.products.error);
  
  const popularProducts = products ? products.filter((product) => product.category === category): [];
  const wokProducts = products ? products.filter((product) => product.category === 1): [];
  const pizzaProducts = products ? products.filter((product) => product.category === 2): [];
  const rollsProducts = products ? products.filter((product) => product.category === 3): [];
  const rollszapechenyeProducts = products ? products.filter((product) => product.category === 4): [];
  const sushiProducts = products ? products.filter((product) => product.category === 5): [];
  const drinksProducts = products ? products.filter((product) => product.category === 6): [];
  const dessertsProducts = products ? products.filter((product) => product.category === 7): [];

  console.log(products)
  return (
    <div>
      <div className="container">
        <Information />
        <div className="content__top">
          <nav className="categories">
            <ul>
              <img
                width="38"
                src={logoSvg}
                alt="Pizza logo"
                onClick={() => scrollToContent("top")}
                className="logo"
              />
              <li onClick={() => scrollToContent("popular", 100)}>
                Популярное
              </li>
              <li onClick={() => scrollToContent("wok", 100)}>Воки</li>
              <li onClick={() => scrollToContent("pizza", 100)}>Пицца</li>
              <li onClick={() => scrollToContent("rolls", 100)}>Роллы</li>
              <li onClick={() => scrollToContent("rollszapechenye", 100)}>Запеченные роллы</li>
              <li onClick={() => scrollToContent("sushi", 100)}>Суши</li>
              <li onClick={() => scrollToContent("drinks", 100)}>Напитки</li>
              <li onClick={() => scrollToContent("desserts", 100)}>Десерты</li>
            </ul>
          </nav>
        </div>

        <ul className="content__list">

          <li id="popular">
            <h2 className="content__title">Популярное</h2>
            <div className="content__items">
            {status === 'loading' && <p>Загрузка...</p>}
              {status === 'failed' && <p>Error: {error}</p>}
              {status === 'succeeded' && popularProducts.map((product) => (
                <ProductBlock key={product.id} {...product} />
              ))}
            </div>
          </li>

          <li id="wok">
            <h2 className="content__title">Воки</h2>
            <div className="content__items">
            {status === 'loading' && <p>Загрузка...</p>}
              {status === 'failed' && <p>Error: {error}</p>}
              {status === 'succeeded' && wokProducts.map((product) => (
                <ProductBlock key={product.id} {...product} />
              ))}
            </div>
          </li>

          <li id="pizza">
            <h2 className="content__title">Пицца</h2>
            <div className="content__items">
            {status === 'loading' && <p>Загрузка...</p>}
              {status === 'failed' && <p>Error: {error}</p>}
              {status === 'succeeded' && pizzaProducts.map((product) => (
                <ProductBlock key={product.id} {...product} />
              ))}
            </div>
          </li>

          <li id="rolls">
            <h2 className="content__title">Роллы</h2>
            <div className="content__items">
            {status === 'loading' && <p>Загрузка...</p>}
              {status === 'failed' && <p>Error: {error}</p>}
              {status === 'succeeded' && rollsProducts.map((product) => (
                <ProductBlock key={product.id} {...product} />
              ))}
            </div>
          </li>

          <li id="rollszapechenye">
            <h2 className="content__title">Запеченные роллы</h2>
            <div className="content__items">
            {status === 'loading' && <p>Загрузка...</p>}
              {status === 'failed' && <p>Error: {error}</p>}
              {status === 'succeeded' && rollszapechenyeProducts.map((product) => (
                <ProductBlock key={product.id} {...product} />
              ))}
            </div>
          </li>

          <li id="sushi">
            <h2 className="content__title">Суши</h2>
            <div className="content__items">
            {status === 'loading' && <p>Загрузка...</p>}
              {status === 'failed' && <p>Error: {error}</p>}
              {status === 'succeeded' && sushiProducts.map((product) => (
                <ProductBlock key={product.id} {...product} />
              ))}
            </div>
          </li>

          <li id="drinks">
            <h2 className="content__title">Напитки</h2>
            <div className="content__items">
            {status === 'loading' && <p>Загрузка...</p>}
              {status === 'failed' && <p>Error: {error}</p>}
              {status === 'succeeded' && drinksProducts.map((product) => (
                <ProductBlock key={product.id} {...product} />
              ))}
            </div>
          </li>
          
          <li id="desserts">
            <h2 className="content__title">Десерты</h2>
            <div className="content__items">
            {status === 'loading' && <p>Загрузка...</p>}
              {status === 'failed' && <p>Error: {error}</p>}
              {status === 'succeeded' && dessertsProducts.map((product) => (
                <ProductBlock key={product.id} {...product} />
              ))}
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Home;
