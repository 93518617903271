import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue } from 'firebase/database';
import { db } from '../../firebase/firebaseConfig'; 
import {  get } from 'firebase/database';
import "../AdminPage/AdminPage.css";
import { remove } from 'firebase/database';

const AdminPage = () => {
  const [orders, setOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        const ordersRef = ref(db, 'orders/');
        const snapshot = await get(ordersRef);
        
        if (snapshot.exists()) {
          const data = snapshot.val();
          const ordersArray = Object.keys(data).map(key => ({
            id: key,
            ...data[key]
          }));
          ordersArray.sort((a, b) => b.timestamp - a.timestamp);
          setOrders(ordersArray);
        } else {
          setOrders([]);
        }
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, []);

  if (loading) {
    return <p>Загрузка...</p>;
  }

  if (error) {
    return <p>Error: {error}</p>;
  }

  const handleDeleteOrder = async (orderId) => {
    try {
      const orderRef = ref(db, `orders/${orderId}`);
      await remove(orderRef);
      console.log('Order deleted successfully!');
      setOrders(orders.filter(order => order.id !== orderId));
    } catch (error) {
      console.error('Error deleting order: ', error);
    }
  };

  return (
    <div className="admin-page">
      <h1>Заказы</h1>
      {orders.length === 0 ? (
        <p>заказы не найдены.</p>
      ) : (
        <table>
          <thead>
            <tr>
              <th>Имя</th>
              <th>Номер телефона</th>
              <th>Адрес</th>
              <th>Продукты</th>
              <th>Цена</th>
              <th>Время заказа</th>
              <th>Управление</th>
            </tr>
          </thead>
          <tbody>
            {orders.map(order => (
              <tr key={order.id}>
                <td>{order.name}</td>
                <td>{order.phone}</td>
                <td>{order.address}</td>
                <td>{order.items ? order.items.map(item => `${item.title} (${item.count})`).join(', ') : 'No items'}</td>
                <td>{order.totalPrice} ₽</td>
                <td>{new Date(order.timestamp).toLocaleString()}</td>
                <td>
                <button onClick={() => handleDeleteOrder(order.id)}>Удалить</button>
              </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default AdminPage;