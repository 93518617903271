import React, { useState } from "react";
import { auth } from "../../firebase/firebaseConfig";
import { signInWithEmailAndPassword } from "firebase/auth";
import "../Login/Login.scss";
import { login } from '../../redux/slices/userSlice';
import { useDispatch } from 'react-redux';

const Login = ({ onRegisterClick, onClose }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  console.log({ email, password })
  const dispatch = useDispatch();

  async function handleLogin (e){
    e.preventDefault();
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      dispatch(login(user));
      onClose();
    } catch (error) {
      console.error("Error logging in: ", error);
      alert(error.message);
    }
  };

  return (
    <div className="modal">
      <div className="modal__content">
        <div className="modal__close" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 50"
            width="50px"
            height="50px"
          >
            <path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z" />
          </svg>
        </div>
        <h2>Авторизация</h2>
        <form className="modal__form" onSubmit={(e) => handleLogin(e)}>
          <input
            className="modal__input-email"
            type="email"
            placeholder="Email"
            defaultValue={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className="modal__input-password"
            type="password"
            placeholder="Пароль"
            defaultValue={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit" className="modal__login">
            Войти
          </button>
        </form>
        <p onClick={onRegisterClick} className="modal__registration">
          Регистрация
        </p>
      </div>
    </div>
  );
};

export default Login;
