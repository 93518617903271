import "../src/scss/app.scss";
import Header from "../src/components/Header/Header";
import Home from "./pages/Home/Home";
import Cart from "../src/pages/Cart/Cart";
import React, { useState } from "react";
import { Route, Routes } from "react-router-dom";
import Footer from "../src/components/Footer/Footer";
import Profile from "./pages/Profile/Profile";
import { AuthProvider , useAuth} from './AuthProvider';
import PrivateRoute from './PrivateRoute';
import Login from '../src/components/Login/Login';
import Register from '../src/components/Register/Register';
import About from "./pages/About/About";
import Delivery from "./pages/Delivery/Delivery";
import Payment from "./pages/Payment/Payment";
import Privacy from "./pages/Privacy/Privacy";
import Rules from "./pages/Rules/Rules";
import AdminPage from "./pages/AdminPage/AdminPage";
import store from '../src/redux/store';
import { Provider } from 'react-redux';
import { useSelector } from 'react-redux';

function App() {
  const [isLoginOpen, setIsLoginOpen] = useState(false);
  const [isRegisterOpen, setIsRegisterOpen] = useState(false);
  const currentUser = useSelector((state) => state.user?.currentUser);

  const handleLoginOpen = () => setIsLoginOpen(true);
  const handleLoginClose = () => setIsLoginOpen(false);
  const handleRegisterOpen = () => {
    setIsLoginOpen(false);
    setIsRegisterOpen(true);
  };
  const handleRegisterClose = () => setIsRegisterOpen(false);

  return (
    <Provider  store={store}>
      <AuthProvider>
    <div className="App">
      <div className="wrapper">
            <Header currentUser={currentUser} onProfileClick={handleLoginOpen}/>
            {!currentUser && isLoginOpen && <Login onRegisterClick={handleRegisterOpen} onClose={handleLoginClose} />}
            {!currentUser && isRegisterOpen && <Register onClose={handleRegisterClose} />}
            <Routes >
              <Route path="/" element={<Home />} />
              <Route path="/cart" element={<Cart />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/about" element={<About />} />
              <Route path="/delivery" element={<Delivery />} />
              <Route path="/payment" element={<Payment />} />
              <Route path="/privacy" element={<Privacy />} />
              <Route path="/rules" element={<Rules />} />
              <Route path="/admin" element={<AdminPage />} />
            </Routes>
      </div>
      <Footer />
    </div>
    </AuthProvider>
    </Provider>
  );
}

export default App;
