import React from "react";
import timeSvg from '../../assets/img/time.svg'
import walletSvg from '../../assets/img/wallet.svg'
import transportSvg from '../../assets/img/transport.svg'
import deliverySvg from '../../assets/img/delivery.svg'

const Information = () => {
  return (
    <div className="information">
      <div className="information__top">
        <h3>Информация о доставке</h3>
      </div>
      <div className="information__content">
        <ul className="information__list">
          <li className="information__list-item">
            <div className="information__list-item-inner">
            <img src={timeSvg} width="38" className="information__time-label"></img>
              <div className="information__time-info">
                <h4 className="information__time">до 45 мин.</h4>
                <p className="information__time-description">ВРЕМЯ ДОСТАВКИ</p>
              </div>
            </div>
          </li>
          <li className="information__list-item">
            <div className="information__list-item-inner">
              <img src={walletSvg} width="38" className="information__time-label"></img>
              <div className="information__time-info">
                <h4 className="information__time">300 ₽</h4>
                <p className="information__time-description">МИН. СУММА ЗАКАЗА</p>
              </div>
            </div>
          </li>
          <li className="information__list-item">
            <div className="information__list-item-inner">
            <img src={deliverySvg} width="38" className="information__time-label"></img>
              <div className="information__time-info">
                <h4 className="information__time">200 ₽</h4>
                <p className="information__time-description">СТОИМ. ДОСТАВКИ</p>
              </div>
            </div>
          </li>
          <li className="information__list-item">
            <div className="information__list-item-inner">
                <img src={transportSvg} width="38" className="information__time-label"></img>
              <div className="information__time-info">
                <h4 className="information__time">от 2 000 ₽</h4>
                <p className="information__time-description">БЕСПЛ. ДОСТАВКА</p>
              </div>
            </div>
          </li>
        </ul>
      </div>

      
    </div>
  );
};

export default Information;
