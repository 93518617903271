import { ref, set, get } from "firebase/database";
import { db } from "./firebaseConfig";

// Получение данных пользователя
const getUserProfile = async (userId) => {
    const userRef = ref(db, 'users/' + userId);
    const snapshot = await get(userRef);

    if (snapshot.exists()) {
        return snapshot.val();
      } else {
        console.log("No data available");
        return null;
      }
    };

// Обновление данных пользователя
const updateUserProfile = async (userId, profile) => {
    const userRef = ref(db, 'users/' + userId);
    await set(userRef, {
        email: profile.email,
        name: profile.name,
        address: profile.address,
        phone: profile.phone
      });
  };

export { getUserProfile, updateUserProfile };
