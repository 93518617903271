import React, { useState } from "react";
import { auth } from "../../firebase/firebaseConfig";
import { createUserWithEmailAndPassword } from "firebase/auth";
import "../Register/Register.scss";

const Register = ({ onClose }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  console.log({ email, password })

  async function handleRegister (e){
    e.preventDefault();
    try {
      await createUserWithEmailAndPassword(auth, email, password);
      onClose();
    } catch (error) {
      console.error("Error registering: ", error);
      alert(error.message);
    }
  };

  return (
    <div className="modal__register">
      <div className="modal__content-register">
        <div className="modal__close" onClick={onClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 50 50"
            width="50px"
            height="50px"
          >
            <path d="M 7.71875 6.28125 L 6.28125 7.71875 L 23.5625 25 L 6.28125 42.28125 L 7.71875 43.71875 L 25 26.4375 L 42.28125 43.71875 L 43.71875 42.28125 L 26.4375 25 L 43.71875 7.71875 L 42.28125 6.28125 L 25 23.5625 Z" />
          </svg>
        </div>
        <h2>Регистрация</h2>
        <form onSubmit={(e) => handleRegister(e)} className="modal__form">
          <input
            className="modal__input-email"
            type="email"
            placeholder="Укажите email"
            defaultValue={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <input
            className="modal__input-password"
            type="password"
            placeholder="Придумайте пароль"
            defaultValue={password}
            onChange={(e) => setPassword(e.target.value)}
          />
          <button type="submit" className="modal__login">
            Регистрация
          </button>
        </form>
      </div>
    </div>
  );
};

export default Register;
