import { configureStore } from "@reduxjs/toolkit";
import  cartReducer  from "./slices/cartSlice";
import productsReducer from './slices/productSlice'
import { loadCartState, saveCartState } from './localStorageUtils';
import { loadUserState, saveUserState } from './userLocalStorage';
import userReducer from './slices/userSlice';

const preloadedState = {
  cart: loadCartState(),
  user: loadUserState()
};

export const store = configureStore({
  reducer: {
    cart: cartReducer, 
    products: productsReducer,
    user: userReducer 
  },
  preloadedState,
});

store.subscribe(() => {
  saveCartState(store.getState().cart);
  saveUserState(store.getState().user);
});

export default store;