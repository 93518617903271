import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeItem, clearItems, addItem } from "../../redux/slices/cartSlice";
import { Link } from "react-router-dom";
import { ref, set, push , get} from "firebase/database";
import { db } from "../../firebase/firebaseConfig";
import { useAuth } from "../../AuthProvider";
import { sendCartToDatabase } from "../../firebase/firebaseConfig";

export const Cart = () => {
  const { currentUser } = useAuth();
  const [profile, setProfile] = useState({
    name: "",
    phone: "",
    address: "",
    paymentMethod: "наличными",
  });

  
  useEffect(() => {
    const fetchUserProfile = async () => {
      if (currentUser) {
        const userRef = ref(db, "users/" + currentUser.uid);
        const snapshot = await get(userRef);
        if (snapshot.exists()) {
          setProfile(snapshot.val());
        } else {
          setProfile((prevProfile) => ({
            ...prevProfile,
            email: currentUser.email,
          }));
        }
      }
    };

    fetchUserProfile();
  }, [currentUser]);

  const dispatch = useDispatch();
  const cartItems = useSelector((state) => state.cart.items);

  const totalItems = cartItems.reduce((total, item) => total + item.count, 0);
  const totalPrice = cartItems.reduce(
    (total, item) => total + item.price * item.count,
    0
  );

  useEffect(() => {
    console.log("Current profile:", profile);
    if (currentUser) {
      // Если текущий пользователь авторизован, можно получить данные из Firebase
      // Здесь можно написать код для загрузки данных пользователя
    }
  }, [currentUser]);

 
  console.error(profile.name);

const handleChange = (e) => {
    const { name, value } = e.target;
    setProfile((prevProfile) => ({
      ...prevProfile,
      [name]: value,
    }));
  };

  const handleSaveOrder = async () => {
    if (currentUser && profile && profile.name && profile.phone && profile.address) {
      dispatch(clearItems());
      sendCartToDatabase(currentUser.uid, cartItems, totalPrice);
    } else {
      alert("Please complete your profile before placing an order.");
    }
  };


  

  return (
    <div className="container container--cart">
      <div className="cart">
        <div className="cart__top">
          <h2 className="cart__title">Корзина</h2>
          <span onClick={() => dispatch(clearItems())}>
            <svg
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.5 5H4.16667H17.5"
                stroke="#B6B6B6"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M6.66663 5.00001V3.33334C6.66663 2.89131 6.84222 2.46739 7.15478 2.15483C7.46734 1.84227 7.89127 1.66667 8.33329 1.66667H11.6666C12.1087 1.66667 12.5326 1.84227 12.8451 2.15483C13.1577 2.46739 13.3333 2.89131 13.3333 3.33334V5.00001M15.8333 5.00001V16.6667C15.8333 17.1087 15.6577 17.5326 15.3451 17.8452C15.0326 18.1577 14.6087 18.3333 14.1666 18.3333H5.83329C5.39127 18.3333 4.96734 18.1577 4.65478 17.8452C4.34222 17.5326 4.16663 17.1087 4.16663 16.6667V5.00001H15.8333Z"
                stroke="#B6B6B6"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M8.33337 9.16667V14.1667"
                stroke="#B6B6B6"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
              <path
                d="M11.6666 9.16667V14.1667"
                stroke="#B6B6B6"
                strokeWidth="1.2"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
          </span>
        </div>
      </div>

      <div className="cart__items">
        {cartItems.map((item) => (
          <div key={item.id} className="cart-item">
            <div className="cart-item__details">
              <img
                src={item.imageUrl}
                alt={item.title}
                className="cart-item__image"
              />
              <div className="cart-item__info">
                <h4>{item.title}</h4>
                <p>{item.weight} г.</p>
              </div>
            </div>

            <div className="cart__block">
              <div className="cart__block-buttons">
                <button
                  onClick={() => dispatch(removeItem(item.id))}
                  className="product__button--remove"
                >
                  -
                </button>
                <span>{item.count}</span>
                <button
                  onClick={() => dispatch(addItem(item))}
                  className="product__button--add"
                >
                  +
                </button>
              </div>
              <div className="cart-item__price">
                <b>{item.price * item.count} ₽</b>
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="order-form">
        <h2>Оформление заказа</h2>
        <form>
          <div className="form-group">
            <label htmlFor="name">Имя *</label>
            <input
              className="profile__input profile__input--name"
              type="text"
              name="name"
              value={profile.name}
              onChange={handleChange}
              placeholder="Имя"
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone">Телефон *</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={profile.phone}
              onChange={handleChange}
              placeholder="Номер телефона"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="address">Адрес *</label>
            <input
              type="text"
              id="address"
              name="address"
              value={profile.address}
              onChange={handleChange}
              placeholder="Адрес"
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="paymentMethod">Способ оплаты *</label>
            <select
              id="paymentMethod"
              name="paymentMethod"
              value={profile.paymentMethod}
              onChange={handleChange}
              required
            >
              <option value="наличными">Наличными</option>
              <option value="картой">Картой курьеру</option>
            </select>
          </div>
        </form>
      </div>

      <div className="cart__bottom">
        <div className="cart__bottom-details">
          <span>
            {" "}
            Всего товаров: <b>{totalItems}</b>{" "}
          </span>
          <span>
            {" "}
            Сумма заказа: <b>{totalPrice}</b>{" "}
          </span>
        </div>
        <div className="cart__bottom-buttons">
          <Link
            to="/"
            className="button button--outline button--add go-back-btn"
          >
            <svg
              width="8"
              height="14"
              viewBox="0 0 8 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 13L1 6.93015L6.86175 1"
                stroke="#D3D3D3"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              ></path>
            </svg>
            <span>Назад</span>
          </Link>
          <div className="button pay-btn" onClick={handleSaveOrder}>
            <span>Оформить</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Cart;
