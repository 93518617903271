import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { removeItem, clearItems, addItem } from "../../redux/slices/cartSlice";
import { Link } from "react-router-dom";

const About = () => {
    return(
        <div>

        </div>
)
}

export default About;
