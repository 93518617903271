// src/features/products/productsSlice.js
import {configureStore, createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { getDatabase, ref, get } from "firebase/database";
import { db } from "../../firebase/firebaseConfig";

const initialState = {
  products: [],
  status: "idle",
  error: null,
};
/*
export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async (category) => {
    const response = await fetch(
      "https://668ec4ddbf9912d4c92fa8e5.mockapi.io/products?category=&{category}"
    );
    const data = await response.json();
    console.log("Fetched products:", data);
    return data;
  }
);
*/
export const fetchProducts = createAsyncThunk(
  "products/fetchProducts",
  async () => {
    const db = getDatabase();
    const productsRef = ref(db, "products");
    const snapshot = await get(productsRef);
    if (snapshot.exists()) {
      const data = snapshot.val();
      return Object.values(data);
    } else {
      throw new Error("No products available");
    }
  }
);


const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProducts.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchProducts.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.products = action.payload;
      })
      .addCase(fetchProducts.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const store = configureStore({
  reducer: {
    products: productsSlice.reducer,

  },
});

export default productsSlice.reducer;
