import React from "react";
import "../Footer/footer.scss";
import { Link, useLocation } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer__container">
        <ul className="footer__links">
          <li >
            <Link to="/about" className="footer__link">О нас</Link>
          </li>
          <li>
            <Link to="/delivery" className="footer__link">Доставка</Link>
          </li>
          <li>
            <Link to="/payment" className="footer__link">Оплата</Link>
          </li>
          <li>
            <Link to="/privacy" className="footer__link">Политика конфиденциальности</Link>
          </li>
          <li>
            <Link to="/rules" className="footer__link">Правила оказания услуг</Link>
          </li>
        </ul>
        <div className="footer__contacts">
        <a href="tel:+78432119081" className="footer__phone-text">
              +7 (843)211-90-81
            </a>
        </div>

      </div>

    </footer>
  );
};

export default Footer;
