import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  totalPrice: 0,
  items: [],
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    addItem: (state, action) => {
      const findItem = state.items.find((i) => i.id === action.payload.id);
      if (findItem) {
        findItem.count++;
      } else {
        state.items.push({
          ...action.payload,
          count: 1,
        });
      }
      state.totalPrice = state.items.reduce((sum, obj) => {
        return (obj.price * obj.count) + sum;
      }, 0);
    },
    removeItem: (state, action) => {
      const idToRemove = action.payload;
      const itemIndex = state.items.findIndex(item => item.id === idToRemove);

      if (itemIndex !== -1) {
        if (state.items[itemIndex].count > 1) {
          state.items[itemIndex].count -= 1;
        } else {
          state.items.splice(itemIndex, 1);
        }
        state.totalPrice = state.items.reduce((sum, obj) => (obj.price * obj.count) + sum, 0);
      }
    },
    clearItems: (state) => {
      state.items = [];
      state.totalPrice = 0;
    },
  },
});

export const { addItem, removeItem, clearItems } = cartSlice.actions;

export default cartSlice.reducer;
