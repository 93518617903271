import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { addItem, clearItems, removeItem  } from "../../redux/slices/cartSlice";

function ProductBlock({
  id,
  imageUrl,
  title,
  description,
  weight,
  price,
  category,
}) {


  //const dispatch = useDispatch();
  /*
  const cartItem = useSelector((state) =>
    state.cart.items.find((obj) => obj.id === id)
  );
  const addedCount = cartItem ? cartItem.count : 0;

  const onClickAdd = () => {
    const item = {
      id,
      imageUrl,
      title,
      description,
      weight,
      price,
      category,
    };
    dispatch(addItem(item));
  };

  */
  const dispatch = useDispatch()
  const cartItem = useSelector((state) => 
    state.cart.items.find((i) => i.id === id))
  let addedCount = cartItem ? cartItem.count: 0

  const onClickAdd = () => {
    const item = { id, imageUrl, title, description, weight, price, category };
    dispatch(addItem(item));
  };

  const onClickRemove = () => {
    dispatch(removeItem(id));
  };
  
  console.log(addedCount)
  return (
    <div className="product">
      <img className="product__image" src={imageUrl} alt="product-image" />
      <div className="product__block">
        <h4 className="product__block__title">{title}</h4>
        <p className="product__block__description">{description}</p>
        <h4 className="product__block__weight">{weight} г.</h4>
        <div className="product__block__bottom">
          <div className="product__block__price">{price} ₽</div>

          {addedCount > 0 ? (
        <div className="product__count-buttons">
          <button onClick={onClickRemove} className="product__button--remove">
            -
          </button>
          <span>{addedCount}</span>
          <button onClick={onClickAdd} className="product__button--add">
            +
          </button>
        </div>
      ) : (
        <button onClick={onClickAdd} className="button button--outline button--add">
          <svg
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.8 4.8H7.2V1.2C7.2 0.5373 6.6627 0 6 0C5.3373 0 4.8 0.5373 4.8 1.2V4.8H1.2C0.5373 4.8 0 5.3373 0 6C0 6.6627 0.5373 7.2 1.2 7.2H4.8V10.8C4.8 11.4627 5.3373 12 6 12C6.6627 12 7.2 11.4627 7.2 10.8V7.2H10.8C11.4627 7.2 12 6.6627 12 6C12 5.3373 11.4627 4.8 10.8 4.8Z"
              fill="white"
            />
          </svg>
          <span>Добавить</span>
        </button>
      )}
        </div>
      </div>
    </div>
  );
}

export default ProductBlock;
